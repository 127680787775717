<template>
  <div id="threed">
    <div id="container"></div>
    <div class="loading">
      <div class="loading_d">
        <!-- <div class="loading_t">正在加载</div> -->
        <!-- <div class="ld" v-if="percentage != 100">
        </div> -->
      </div>
    </div>

    <transition name="el-zoom-in-bottom">
      <div class="hover_name" v-if="hover_show">{{ hover_name }}</div>
    </transition>

    <!-- <div class="btn" @click="testBSE()">测试</div> -->
  </div>
</template>

<script>
import * as THREE from "../../assets/three";
import { OrbitControls } from "../../assets/three/examples/jsm/controls/OrbitControls";
import { FBXLoader } from "../../assets/three/examples/jsm/loaders/FBXLoader.js";
import { Reflector } from "@/assets/three/examples/jsm/objects/Reflector.js";
import { Water } from "@/assets/three/examples/jsm/objects/Water.js";
import { FXAAShader } from "@/assets/three/examples/jsm/shaders/FXAAShader.js";
import { OutlinePass } from "@/assets/three/examples/jsm/postprocessing/OutlinePass.js";
import { EffectComposer } from "@/assets/three/examples/jsm/postprocessing/EffectComposer.js";
import { RenderPass } from "@/assets/three/examples/jsm/postprocessing/RenderPass.js";
import { ShaderPass } from "@/assets/three/examples/jsm/postprocessing/ShaderPass.js";
import { UnrealBloomPass } from "@/assets/three/examples/jsm/postprocessing/UnrealBloomPass.js";
import { GUI } from "@/assets/three/examples/jsm/libs/dat.gui.module.js";
import { RectAreaLightUniformsLib } from "@/assets/three/examples/jsm/lights/RectAreaLightUniformsLib.js";

import * as Tween from "gsap";
import Commen from "@/assets/js/Commen.js";
import { EventHandler } from "@/assets/js/eventHandler.js";
// import { Tween, Easing, update } from "@tweenjs/tween.js";
import {
  CSS2DRenderer,
  CSS2DObject,
} from "@/assets/three/examples/jsm/renderers/CSS2DRenderer.js";
import $ from "jquery";

import { gsap } from "gsap";
import { MeshoptDecoder } from "three/examples/jsm/libs/meshopt_decoder.module.js";
import { LineGeometry } from "@/assets/three/examples/jsm/lines/LineGeometry";
const SceneScale = 0.001;

export default {
  name: "threed",
  components: {},
  props: ["scene_pos", "fig"],
  data() {
    return {
      clock: new THREE.Clock(),
      info_show: false,
      hover_show: false,
      hover_name: "",
      choose_id: "",
      choose_id1: "",
      stateNow: 1,
      info_show2: false,
      isactiveMap: true,
      flag: false,
      timmer: null,
      idx: 0,
      idxFlag: false,
      percentage: 0,
      windLineBloom: null,
      carSpeed: null,
      time1: null,
      time2: null,
      time3: null,
      time4: null,
      time5: null,
      time6: null,
      time7: null,
    };
  },
  beforeDestroy() {
    this.close_page();
  },

  methods: {
    onload: function () {
      this.layers_0.set(0);
      this.layers_1.set(1);
      this.layers_hide.set(2);
      this.bloomLayer.set(1);
      this.init();
    },

    init: function () {
      this.initScene();
      this.scrollspeed();
      this.scene.add(this.group_0);
      this.initBackground()
        .then(this.initobj)
        .then((modelInfo) => {
          this.handleModel(modelInfo);
          this.updateProgress(1);
          this.initEvent();
          this.modelBloomEffect();
          this.animate();
          // this.initGui();
        });
      this.initbloom();
    },

    initGui: function () {
      // -------
      let gui = new GUI();
      gui.add(this.params, "exposure", 0.1, 2).onChange((value) => {
        this.renderer.toneMappingExposure = Math.pow(value, 4.0);
      });
      gui.add(this.params, "bloomThreshold", 0.0, 1.0).onChange((value) => {
        this.bloomPass.threshold = Number(value);
      });
      gui.add(this.params, "bloomStrength", 0.0, 3.0).onChange((value) => {
        this.bloomPass.strength = Number(value);
      });
      gui
        .add(this.params, "bloomRadius", 0.0, 1.0)
        .step(0.01)
        .onChange((value) => {
          this.bloomPass.radius = Number(value);
        });
    },
    initScene: function () {
      // console.log(this);
      this.scene = new THREE.Scene();
      this.scene.alpha = 0;
      // this.scene.background = new THREE.Color(0x00000000); // 设置背景色为透明
      // this.bg = new THREE.TextureLoader().load("static/border.png");
      // this.scene.background = this.bg;
      this.container = document.getElementById("container");
      this.camera = new THREE.PerspectiveCamera(
        70,
        this.container.clientWidth / this.container.clientHeight,
        0.2,
        10000
      );

      this.camera.position.set(9.705, 8.415, -20.539);

      this.currentLayer.disableAll();
      this.currentLayer.enable(0);
      this.currentLayer.enable(1);
      this.camera.layers = this.currentLayer;

      this.raycaster = new THREE.Raycaster();

      this.renderer = new THREE.WebGLRenderer({
        antialias: true,
        alpha: true,
      });
      this.renderer.setClearAlpha(0);
      this.renderer.shadowMap.enabled = true;
      this.renderer.shadowMap.type = THREE.PCFShadowMap;
      this.renderer.setSize(
        this.container.clientWidth,
        this.container.clientHeight
      );
      this.renderer.setPixelRatio(window.devicePixelRatio * 2);
      this.container.appendChild(this.renderer.domElement);
      this.renderer.setClearColor(0xffffff, 0);
      this.renderer.toneMapping = THREE.NoToneMapping; //LinearToneMapping//NoToneMapping//ReinhardToneMapping
      this.css2DRenderer = new CSS2DRenderer();
      this.css2DRenderer.setSize(
        this.container.clientWidth,
        this.container.clientHeight
      );
      this.css2DRenderer.domElement.style.position = "absolute";
      this.css2DRenderer.domElement.style.top = "0px";
      this.css2DRenderer.domElement.style["pointer-events"] = "none";
      this.container.appendChild(this.css2DRenderer.domElement);

      this.controls = new OrbitControls(this.camera, this.renderer.domElement);
      this.controls.enableZoom = false; // 禁止放大缩小
      // this.controls.enabled = false; // 禁止鼠标拖动
      this.controls.target.set(9.705, 16.415, -20.539);
      // -0,
      //     -0,
      //     0,
      //     9.705,
      //     16.415,
      //     -20.539,
      this.controls.autoRotate = true;
      this.controls.autoRotateSpeed = 0.5;
      this.controls.enableDamping = true;
      this.controls.dampingFactor = 0.05;
      // this.controls.mouseButtons = {
      //   LEFT: THREE.MOUSE.LEFT,
      //   MIDDLE: THREE.MOUSE.LEFT,
      //   RIGHT: THREE.MOUSE.LEFT,
      // };
      this.controls.mouseButtons.RIGHT = THREE.MOUSE.LEFT;
      this.controls.mouseButtons.LEFT = null;
      this.controls.mouseButtons.MIDDLE = null;
      this.controls.update();
      this.renderer.domElement.removeAttribute("tabindex");
      var alight = new THREE.AmbientLight(0xffffff, 0.95);

      //alight.layers = this.layers_0;
      this.scene.add(alight);
      this.dirLight = new THREE.DirectionalLight(0xffffff, 0.2);
      this.dirLight.castShadow = true;
      // this.dirLight.position.set(0, 0, 0);
      this.dirLight.position.set(-1, 3, 1);
      // this.objs.Mesh["juanlianmen"].position.set(-1, 2, -1);
      this.dirLight.target.position.copy(this.scene.position);
      this.dirLight.shadow.mapSize.width = 1024 * 2; // default
      this.dirLight.shadow.mapSize.height = 1024 * 2; // default
      let l = 200;
      this.dirLight.shadow.camera.near = 0 - l; //产生阴影的最近距离
      this.dirLight.shadow.camera.far = l; //产生阴影的最远距离
      this.dirLight.shadow.camera.left = 0 - l; //产生阴影距离位置的最左边位置
      this.dirLight.shadow.camera.right = l; //最右边
      this.dirLight.shadow.camera.top = l; //最上边
      this.dirLight.shadow.camera.bottom = 0 - l; //最下面
      this.dirLight.shadow.radius = 1;
      this.scene.add(this.dirLight);

      let dl = new THREE.DirectionalLight(0xffffff, 0.1);
      dl.position.set(-1, 0, 1);
      dl.target.position.copy(this.scene.position);
      this.scene.add(dl);

      let dl1 = new THREE.DirectionalLight(0xffffff, 0.1);
      dl1.position.set(1, 0, -1);
      dl1.target.position.copy(this.scene.position);
      this.scene.add(dl1);

      RectAreaLightUniformsLib.init();
      const rectLight = new THREE.RectAreaLight(0xffffff, 1.0, 50, 50);
      rectLight.position.set(3, 5, 3);
      rectLight.lookAt(8.157, 0.018, 3.852);
      this.scene.add(rectLight);
    },

    initobj: function (n = "model") {
      return new Promise((res, rej) => {
        let onProgress = (xhr) => {
          if (xhr.lengthComputable) {
            let a = xhr.loaded / xhr.total;
            a = a * 100;
            a = a.toFixed(0) * 1;
            this.percentage = a;
          }
        };
        let onError = function (xhr) {
          rej(xhr);
          console.log(xhr);
        };
        let loader = new FBXLoader();
        let path = `/model/${n}.fbx`;
        loader.load(
          path,
          (object) => {
            let objs = {};
            object.traverse((child) => {
              if (objs[child.type]) {
                objs[child.type][child.name] = child;
              } else {
                objs[child.type] = {};
                objs[child.type][child.name] = child;
              }
            });

            let mats = {};
            let identify = "name";
            for (let im in objs.Mesh) {
              let mesh = objs.Mesh[im];
              let mat = mesh.material;
              if (Array.isArray(mat)) {
                for (let ma of mat) {
                  if (!mats[ma[identify]]) {
                    mats[ma[identify]] = ma;
                  }
                }
              } else {
                if (!mats[mat[identify]]) {
                  mats[mat[identify]] = mat;
                }
              }
            }

            res({ object: object, objs: objs, mats: mats });
          },
          onProgress,
          onError
        );
      });
    },
    createFlightPaths(startPoint, endPointArray, curveSegments, curveRadius) {
      for (let i = 0; i < endPointArray.length; i++) {
        let endPoint = endPointArray[i];

        // 计算中点
        let midPointX = (startPoint.x + endPoint.x) / 2;
        let midPointY = (startPoint.y + endPoint.y) / 2;
        let midPointZ = (startPoint.z + endPoint.z) / 2;

        // 根据中点和设定的弧度创建贝塞尔曲线
        let curve = new THREE.QuadraticBezierCurve3(
          new THREE.Vector3(startPoint.x, startPoint.y, startPoint.z),
          new THREE.Vector3(midPointX, midPointY + curveRadius, midPointZ),
          new THREE.Vector3(endPoint.x, endPoint.y, endPoint.z)
        );
        let points = curve.getPoints(curveSegments);
        // 创建飞线的路径
        let geometry = new THREE.BufferGeometry().setFromPoints(points);
        // 创建飞线的材质
        let material = new THREE.LineBasicMaterial({ color: 0xff0000 });
        // 创建飞线
        let curveObject = new THREE.Line(geometry, material);
        this.Notoum = new THREE.TextureLoader().load(`textures/t1.png`);
        this.Notoum.wrapS = 1000;
        this.Notoum.wrapT = 1000;
        this.Notoum.rotation = Math.PI / 2;
        this.Notoum.repeat.set(0.01, 0.01);
        this.effectMaterial1 = new THREE.MeshBasicMaterial({
          transparent: true,
          opacity: 0.9,
          map: this.Notoum,
        });
        this.effectMaterial1_show = true;
        curveObject.material = this.effectMaterial1;
        curveObject.layers = this.layers_1;
        // 将飞线添加到场景中
        this.scene.add(curveObject);
      }
    },

    handleModel: function (modelInfo) {
      let { object, objs, mats } = modelInfo;
      let s = new THREE.Vector3(1, 1, 1).multiplyScalar(SceneScale); // 相乘
      object.scale.copy(s);
      object.updateMatrixWorld(); // 矩阵更新
      this.scene.add(object);
      console.log(object);
      this.objs = objs;
      this.mats = mats;
      this.object = object;
      console.log(this);

      // this.rotationSpeed = 0.005;
      // this.dampingFactor = 0.98;
      // this.objs.Group["animation1"].visible = false;
      // 计算边界框并获取中心点
      let mesh = this.objs.Mesh["Sphere"];
      mesh.material.transparent = true;
      mesh.material.opacity = 0;
      mesh.geometry.computeBoundingBox();
      this.addNormalMap();
      Tween.gsap.to(mesh.material, {
        opacity: 1,
        duration: 3,
        ease: "aaa",
      });
      // mesh.material.opacity = 1
      // this.outlinePass.selectedObjects = [mesh];
      const boundingBox = mesh.geometry.boundingBox;
      const center = new THREE.Vector3();
      boundingBox.getCenter(center);
      setTimeout(() => {
        this.controls.target = center;
      }, 1500);
      this.fristRuning();
      setTimeout(() => {
        this.testBSE();
      }, 10000);
      // this.scene.add(new THREE.AxesHelper(500));
    },
    fristRuning() {
      this.testBSEs(new THREE.Vector3(-1.099, 7.092, 6.96), [
        new THREE.Vector3(-1.709, 5.045, 8.461),
        new THREE.Vector3(1.857, 6.835, 7.058),
        new THREE.Vector3(-0.683, 7.495, 6.581),
      ]);
      this.createAnimatedLine(
        new THREE.Vector3(-3.935, -3.856, -8.341),
        new THREE.Vector3(-7.318, -3.673, -5.734)
      );

      this.createAnimatedLine(
        new THREE.Vector3(-2.095, -0.046, -9.773),
        new THREE.Vector3(-1.607, 2.618, -9.511)
      );
      this.createAnimatedLine(
        new THREE.Vector3(6.331, 5.436, -5.508),
        new THREE.Vector3(6.488, 7.077, -2.792)
      );
      this.createAnimatedLine(
        new THREE.Vector3(6.304, 7.34, -2.518),
        new THREE.Vector3(7.404, 7.192, -1.333)
      );
      this.createAnimatedLine(
        new THREE.Vector3(2.186, 8.481, -4.822),
        new THREE.Vector3(2.086, 6.451, -7.346)
      );
      this.createAnimatedLine(
        new THREE.Vector3(-3.607, -1.324, -9.227),
        new THREE.Vector3(-5.011, -0.364, -8.645)
      );
      this.createAnimatedLine(
        new THREE.Vector3(-5.456, -1.685, -8.208),
        new THREE.Vector3(-5.9, -0.605, -8.045)
      );
      this.createAnimatedLine(
        new THREE.Vector3(-4.09, -4.956, -7.656),
        new THREE.Vector3(-5.256, -3.796, -7.609)
      );

      this.createAnimatedLine(
        new THREE.Vector3(1.943, -8.073, 5.569),
        new THREE.Vector3(1.624, -9.287, 3.328)
      );
      this.createAnimatedLine(
        new THREE.Vector3(-1.509, -9.854, -0.778),
        new THREE.Vector3(1.382, -9.901, -0.176)
      );
      this.createAnimatedLine(
        new THREE.Vector3(-1.817, -9.344, -3.061),
        new THREE.Vector3(-2.656, -9.561, -1.235)
      );
      this.createAnimatedLine(
        new THREE.Vector3(-0.961, -9.72, -2.138),
        new THREE.Vector3(-2.989, -9.274, -2.241)
      );
      this.createAnimatedLine(
        new THREE.Vector3(-0.468, -9.362, -3.484),
        new THREE.Vector3(0.961, -9.669, -2.362)
      );
      this.createAnimatedLine(
        new THREE.Vector3(-3.813, -4.518, -8.06),
        new THREE.Vector3(-7.037, -5.176, -4.859)
      );
      this.createAnimatedLine(
        new THREE.Vector3(-6.514, -6.049, -4.573),
        new THREE.Vector3(-7.642, -2.864, -5.776)
      );
      this.createAnimatedLine(
        new THREE.Vector3(-6.749, -0.226, -7.368),
        new THREE.Vector3(-6.49, -3.835, -6.568)
      );
      this.createAnimatedLine(
        new THREE.Vector3(-1.652, 8.856, -4.335),
        new THREE.Vector3(-2.578, 7.631, -5.921)
      );
      this.createAnimatedLine(
        new THREE.Vector3(8.167, 5.657, -1.121),
        new THREE.Vector3(6.214, 7.71, -1.375)
      );
      this.createAnimatedLine(
        new THREE.Vector3(3.591, 9.226, -1.401),
        new THREE.Vector3(2.125, 9.588, 1.88)
      );
      this.createAnimatedLine(
        new THREE.Vector3(7.803, -5.59, -2.793),
        new THREE.Vector3(9.154, -3.333, -2.232)
      );
      this.createAnimatedLine(
        new THREE.Vector3(9.828, 1.11, -1.444),
        new THREE.Vector3(7.868, 3.331, -5.19)
      );
      this.createAnimatedLine(
        new THREE.Vector3(8.887, 3.912, 2.385),
        new THREE.Vector3(9.815, 1.275, 1.403)
      );
      this.createAnimatedLine(
        new THREE.Vector3(9.399, 2.244, 2.568),
        new THREE.Vector3(9.298, 3.286, -1.627)
      );
      this.createAnimatedLine(
        new THREE.Vector3(8.1, 5.753, -1.119),
        new THREE.Vector3(9.081, 3.238, -2.639)
      );
      this.testBSEs(new THREE.Vector3(-0.953, 5.896, -8.017), [
        new THREE.Vector3(2.243, 5.241, -8.214),
        new THREE.Vector3(-0.303, 4.322, -9.006),
        new THREE.Vector3(-2.042, 7.519, -6.266),
      ]);
      this.createAnimatedLine(
        new THREE.Vector3(0.6, 7.255, -6.852),
        new THREE.Vector3(-2.448, 6.119, -7.518)
      );
      this.createAnimatedLine(
        new THREE.Vector3(4.951, 5.661, -6.586),
        new THREE.Vector3(3.877, 4.767, -7.888)
      );
      this.createAnimatedLine(
        new THREE.Vector3(6.557, 3.284, -6.792),
        new THREE.Vector3(4.806, 5.795, -6.576)
      );

      this.testBSEs(new THREE.Vector3(-3.934, 0.017, -9.19), [
        new THREE.Vector3(-1.914, 0.359, -9.807),
        new THREE.Vector3(-2.683, 1.91, -9.438),
        new THREE.Vector3(-5.724, 1.338, -8.083),
      ]);
      this.testBSEs(new THREE.Vector3(4.064, 8.4, -3.59), [
        new THREE.Vector3(5.588, 6.695, -4.891),
        new THREE.Vector3(2.908, 7.882, -5.419),
        new THREE.Vector3(3.773, 7.05, -5.999),
      ]);
      this.createAnimatedLine(
        new THREE.Vector3(3.326, 0.665, 9.404),
        new THREE.Vector3(5.538, 0.286, 8.32)
      );
      this.createAnimatedLine(
        new THREE.Vector3(2.955, -1.084, 9.485),
        new THREE.Vector3(2.837, -2.386, 9.281)
      );
      this.createAnimatedLine(
        new THREE.Vector3(5.026, -2.662, 8.218),
        new THREE.Vector3(4.378, -0.674, 8.964)
      );
      this.createAnimatedLine(
        new THREE.Vector3(7.22, -1.869, 6.653),
        new THREE.Vector3(6.537, -2.912, 6.978)
      );
      this.createAnimatedLine(
        new THREE.Vector3(3.868, -3.406, 8.563),
        new THREE.Vector3(3.526, -4.661, 8.109)
      );
      this.createAnimatedLine(
        new THREE.Vector3(2.621, -6.567, 7.066),
        new THREE.Vector3(4.043, -5.92, 6.97)
      );

      this.createAnimatedLine(
        new THREE.Vector3(1.471, 6.04, -7.829),
        new THREE.Vector3(-0.178, 6.891, -7.24)
      );
      this.createAnimatedLine(
        new THREE.Vector3(1.018, 3.996, -9.105),
        new THREE.Vector3(-1.696, 4.083, -8.967)
      );

      this.createAnimatedLine(
        new THREE.Vector3(-3.863, 9.046, -1.79),
        new THREE.Vector3(-4.892, 7.9, -3.692)
      );
      this.createAnimatedLine(
        new THREE.Vector3(-4.397, 7.411, -5.069),
        new THREE.Vector3(-5.322, 8.229, -1.974)
      );
      this.createAnimatedLine(
        new THREE.Vector3(-3.104, 7.196, 6.208),
        new THREE.Vector3(-0.395, 7.63, 6.449)
      );
      this.createAnimatedLine(
        new THREE.Vector3(1.516, 8.469, 5.092),
        new THREE.Vector3(0.963, 9.283, 3.589)
      );
      this.createAnimatedLine(
        new THREE.Vector3(0.293, 9.863, 1.622),
        new THREE.Vector3(-0.73, 9.062, 4.16)
      );
      this.createAnimatedLine(
        new THREE.Vector3(0.451, 5.656, 8.231),
        new THREE.Vector3(-1.643, 4.264, 8.89)
      );
      this.createAnimatedLine(
        new THREE.Vector3(-0.109, 3.032, 9.524),
        new THREE.Vector3(1.161, 3.8, 9.175)
      );
      this.createAnimatedLine(
        new THREE.Vector3(1.311, 4.605, 8.774),
        new THREE.Vector3(-3.064, 4.942, 8.13)
      );
    },
    testBSE() {
      this.time1 = setInterval(() => {
        this.createAnimatedLine(
          new THREE.Vector3(-1.817, -9.344, -3.061),
          new THREE.Vector3(-2.656, -9.561, -1.235)
        );
        this.createAnimatedLine(
          new THREE.Vector3(-0.961, -9.72, -2.138),
          new THREE.Vector3(-2.989, -9.274, -2.241)
        );
        this.createAnimatedLine(
          new THREE.Vector3(-0.468, -9.362, -3.484),
          new THREE.Vector3(0.961, -9.669, -2.362)
        );
        this.createAnimatedLine(
          new THREE.Vector3(8.167, 5.657, -1.121),
          new THREE.Vector3(6.214, 7.71, -1.375)
        );
        this.createAnimatedLine(
          new THREE.Vector3(-3.935, -3.856, -8.341),
          new THREE.Vector3(-7.318, -3.673, -5.734)
        );
        this.createAnimatedLine(
          new THREE.Vector3(-4.09, -4.956, -7.656),
          new THREE.Vector3(-5.256, -3.796, -7.609)
        );
        this.createAnimatedLine(
          new THREE.Vector3(-3.813, -4.518, -8.06),
          new THREE.Vector3(-7.037, -5.176, -4.859)
        );
        this.createAnimatedLine(
          new THREE.Vector3(3.591, 9.226, -1.401),
          new THREE.Vector3(2.125, 9.588, 1.88)
        );
        this.createAnimatedLine(
          new THREE.Vector3(7.803, -5.59, -2.793),
          new THREE.Vector3(9.154, -3.333, -2.232)
        );
        this.createAnimatedLine(
          new THREE.Vector3(9.828, 1.11, -1.444),
          new THREE.Vector3(7.868, 3.331, -5.19)
        );
        this.createAnimatedLine(
          new THREE.Vector3(8.887, 3.912, 2.385),
          new THREE.Vector3(9.815, 1.275, 1.403)
        );
        this.createAnimatedLine(
          new THREE.Vector3(9.399, 2.244, 2.568),
          new THREE.Vector3(9.298, 3.286, -1.627)
        );
        this.createAnimatedLine(
          new THREE.Vector3(8.1, 5.753, -1.119),
          new THREE.Vector3(9.081, 3.238, -2.639)
        );
        this.testBSEs(new THREE.Vector3(-0.953, 5.896, -8.017), [
          new THREE.Vector3(2.243, 5.241, -8.214),
          new THREE.Vector3(-0.303, 4.322, -9.006),
          new THREE.Vector3(-2.042, 7.519, -6.266),
        ]);
        this.createAnimatedLine(
          new THREE.Vector3(0.6, 7.255, -6.852),
          new THREE.Vector3(-2.448, 6.119, -7.518)
        );
        this.createAnimatedLine(
          new THREE.Vector3(4.951, 5.661, -6.586),
          new THREE.Vector3(3.877, 4.767, -7.888)
        );
        this.createAnimatedLine(
          new THREE.Vector3(6.557, 3.284, -6.792),
          new THREE.Vector3(4.806, 5.795, -6.576)
        );
      }, 5000);
      this.time2 = setInterval(() => {
        this.testBSEs(new THREE.Vector3(-1.099, 7.092, 6.96), [
          new THREE.Vector3(-1.709, 5.045, 8.461),
          new THREE.Vector3(1.857, 6.835, 7.058),
          new THREE.Vector3(-0.683, 7.495, 6.581),
        ]);
        this.createAnimatedLine(
          new THREE.Vector3(-1.652, 8.856, -4.335),
          new THREE.Vector3(-2.578, 7.631, -5.921)
        );
        this.createAnimatedLine(
          new THREE.Vector3(6.331, 5.436, -5.508),
          new THREE.Vector3(6.488, 7.077, -2.792)
        );
        this.createAnimatedLine(
          new THREE.Vector3(6.304, 7.34, -2.518),
          new THREE.Vector3(7.404, 7.192, -1.333)
        );
        this.createAnimatedLine(
          new THREE.Vector3(0.451, 5.656, 8.231),
          new THREE.Vector3(-1.643, 4.264, 8.89)
        );
        this.createAnimatedLine(
          new THREE.Vector3(-0.109, 3.032, 9.524),
          new THREE.Vector3(1.161, 3.8, 9.175)
        );
        this.createAnimatedLine(
          new THREE.Vector3(1.311, 4.605, 8.774),
          new THREE.Vector3(-3.064, 4.942, 8.13)
        );
      }, 6500);

      this.time3 = setInterval(() => {
        this.testBSEs(new THREE.Vector3(4.064, 8.4, -3.59), [
          new THREE.Vector3(5.588, 6.695, -4.891),
          new THREE.Vector3(2.908, 7.882, -5.419),
          new THREE.Vector3(3.773, 7.05, -5.999),
        ]);
        this.createAnimatedLine(
          new THREE.Vector3(3.326, 0.665, 9.404),
          new THREE.Vector3(5.538, 0.286, 8.32)
        );
        this.createAnimatedLine(
          new THREE.Vector3(4.068, 8.727, -2.693),
          new THREE.Vector3(3.497, 8.192, -4.543)
        );
        this.createAnimatedLine(
          new THREE.Vector3(-4.981, -0.358, -8.662),
          new THREE.Vector3(-3.549, -1.291, -9.254)
        );
        this.createAnimatedLine(
          new THREE.Vector3(4.682, 7.396, -4.83),
          new THREE.Vector3(2.186, 8.481, -4.822)
        );
        this.createAnimatedLine(
          new THREE.Vector3(-1.17, -9.92, 0.46),
          new THREE.Vector3(1.279, -9.42, 3.1)
        );
      }, 7500);
      this.time4 = setInterval(() => {
        this.testBSEs(new THREE.Vector3(-3.934, 0.017, -9.19), [
          new THREE.Vector3(-1.914, 0.359, -9.807),
          new THREE.Vector3(-2.683, 1.91, -9.438),
          new THREE.Vector3(-5.724, 1.338, -8.083),
        ]);

        this.createAnimatedLine(
          new THREE.Vector3(1.471, 6.04, -7.829),
          new THREE.Vector3(-0.178, 6.891, -7.24)
        );
        this.createAnimatedLine(
          new THREE.Vector3(1.018, 3.996, -9.105),
          new THREE.Vector3(-1.696, 4.083, -8.967)
        );

        this.createAnimatedLine(
          new THREE.Vector3(-3.863, 9.046, -1.79),
          new THREE.Vector3(-4.892, 7.9, -3.692)
        );
        this.createAnimatedLine(
          new THREE.Vector3(-4.397, 7.411, -5.069),
          new THREE.Vector3(-5.322, 8.229, -1.974)
        );

        this.createAnimatedLine(
          new THREE.Vector3(-3.104, 7.196, 6.208),
          new THREE.Vector3(-0.395, 7.63, 6.449)
        );
        this.createAnimatedLine(
          new THREE.Vector3(1.516, 8.469, 5.092),
          new THREE.Vector3(0.963, 9.283, 3.589)
        );
        this.createAnimatedLine(
          new THREE.Vector3(0.293, 9.863, 1.622),
          new THREE.Vector3(-0.73, 9.062, 4.16)
        );
      }, 9000);

      this.time5 = setInterval(() => {
        this.createAnimatedLine(
          new THREE.Vector3(-0.789, -9.72, 2.206),
          new THREE.Vector3(1.499, -9.855, 0.779)
        );
        this.createAnimatedLine(
          new THREE.Vector3(-0.164, -9.92, -1.247),
          new THREE.Vector3(-0.055, -9.207, -3.899)
        );
        this.createAnimatedLine(
          new THREE.Vector3(-6.563, -2.871, -6.969),
          new THREE.Vector3(-7.62, -0.782, -6.419)
        );
        this.createAnimatedLine(
          new THREE.Vector3(-6.625, -2.439, -7.073),
          new THREE.Vector3(-6.136, -6.673, -4.215)
        );
        this.createAnimatedLine(
          new THREE.Vector3(-7.002, -7.009, -1.343),
          new THREE.Vector3(-7.787, -4.425, -4.445)
        );
        this.createAnimatedLine(
          new THREE.Vector3(2.955, -1.084, 9.485),
          new THREE.Vector3(2.837, -2.386, 9.281)
        );
        this.createAnimatedLine(
          new THREE.Vector3(5.026, -2.662, 8.218),
          new THREE.Vector3(4.378, -0.674, 8.964)
        );
        this.createAnimatedLine(
          new THREE.Vector3(7.22, -1.869, 6.653),
          new THREE.Vector3(6.537, -2.912, 6.978)
        );
        this.createAnimatedLine(
          new THREE.Vector3(3.868, -3.406, 8.563),
          new THREE.Vector3(3.526, -4.661, 8.109)
        );
        this.createAnimatedLine(
          new THREE.Vector3(2.621, -6.567, 7.066),
          new THREE.Vector3(4.043, -5.92, 6.97)
        );
      }, 4000);
      this.time6 = setInterval(() => {
        this.createAnimatedLine(
          new THREE.Vector3(-6.749, -0.226, -7.368),
          new THREE.Vector3(-6.49, -3.835, -6.568)
        );
        this.createAnimatedLine(
          new THREE.Vector3(5.509, -4.563, 6.982),
          new THREE.Vector3(3.328, -4.028, 8.52)
        );
        this.createAnimatedLine(
          new THREE.Vector3(6.819, -3.734, 6.281),
          new THREE.Vector3(5.915, 0.669, 8.029)
        );
        this.createAnimatedLine(
          new THREE.Vector3(9.026, 4.265, 0.525),
          new THREE.Vector3(8.739, 4.568, -1.639)
        );
        this.createAnimatedLine(
          new THREE.Vector3(8.175, 5.75, -0.171),
          new THREE.Vector3(9.379, 3.371, -0.757)
        );
        this.createAnimatedLine(
          new THREE.Vector3(7.465, 6.638, 0.405),
          new THREE.Vector3(7.914, 5.131, -3.317)
        );
        this.createAnimatedLine(
          new THREE.Vector3(8.628, 2.26, -4.511),
          new THREE.Vector3(6.537, 4.016, -6.408)
        );
        this.createAnimatedLine(
          new THREE.Vector3(7.617, 1.916, -6.181),
          new THREE.Vector3(8.341, 0.781, -5.453)
        );
        this.createAnimatedLine(
          new THREE.Vector3(7.855, -2.055, -5.829),
          new THREE.Vector3(9.767, 0.533, -2.059)
        );
        this.createAnimatedLine(
          new THREE.Vector3(7.988, -2.93, -5.25),
          new THREE.Vector3(6.463, -3.253, -6.895)
        );
        this.createAnimatedLine(
          new THREE.Vector3(8.202, -3.961, -4.119),
          new THREE.Vector3(9.553, -1.386, -2.603)
        );
      }, 8500);
      this.time7 = setInterval(() => {
        this.createAnimatedLine(
          new THREE.Vector3(-6.514, -6.049, -4.573),
          new THREE.Vector3(-7.642, -2.864, -5.776)
        );
        this.createAnimatedLine(
          new THREE.Vector3(1.733, -9.764, -1.274),
          new THREE.Vector3(0.123, -9.866, 1.626)
        );
        this.createAnimatedLine(
          new THREE.Vector3(-4.153, 0.363, -9.083),
          new THREE.Vector3(-2.158, -0.128, -9.758)
        );
        this.createAnimatedLine(
          new THREE.Vector3(2.086, 6.451, -7.346),
          new THREE.Vector3(-0.094, 8.061, -5.913)
        );
        this.createAnimatedLine(
          new THREE.Vector3(1.748, 9.826, -0.615),
          new THREE.Vector3(1.438, 9.646, -2.208)
        );
        this.createAnimatedLine(
          new THREE.Vector3(-3.388, 9.147, 2.194),
          new THREE.Vector3(-2.525, 8.661, 4.314)
        );
        this.createAnimatedLine(
          new THREE.Vector3(-3.388, 9.147, 2.194),
          new THREE.Vector3(-2.525, 8.661, 4.314)
        );
        this.createAnimatedLine(
          new THREE.Vector3(-3.104, 7.196, 6.208),
          new THREE.Vector3(-0.395, 7.63, 6.449)
        );
        this.createAnimatedLine(
          new THREE.Vector3(-0.055, -9.207, -3.899),
          new THREE.Vector3(1.941, -9.472, -2.55)
        );
        this.createAnimatedLine(
          new THREE.Vector3(-1.509, -9.854, -0.778),
          new THREE.Vector3(1.382, -9.901, -0.176)
        );
        this.createAnimatedLine(
          new THREE.Vector3(1.943, -8.073, 5.569),
          new THREE.Vector3(1.624, -9.287, 3.328)
        );

        this.createAnimatedLine(
          new THREE.Vector3(4.155, 5.182, -7.47),
          new THREE.Vector3(1.825, 6.736, -7.158)
        );
        this.createAnimatedLine(
          new THREE.Vector3(2.784, 8.869, -3.681),
          new THREE.Vector3(-0.313, 9.454, -3.239)
        );
        this.createAnimatedLine(
          new THREE.Vector3(-4.334, 6.893, -5.801),
          new THREE.Vector3(-1.604, 7.691, -6.183)
        );
        this.createAnimatedLine(
          new THREE.Vector3(2.926, 6.295, -7.195),
          new THREE.Vector3(0.913, 7.892, -6.069)
        );
      }, 12000);
    },
    testBSEs(point1, points) {
      this.createAnimatedLine(point1, points[0]);
      this.createAnimatedLine(point1, points[1]);
      this.createAnimatedLine(point1, points[2]);
    },
    // 根据曲线点计算大致长度
    calculateLineLength(points) {
      let length = 0;
      for (let i = 1; i < points.length; i++) {
        length += points[i].distanceTo(points[i - 1]);
      }
      return length;
    },
    createAnimatedLine(pointA, pointB) {
      let arcHeight = 1;
      // 球心的位置
      let sphereCenter = new THREE.Vector3(0, 0, 0);
      const sphereRadius = 10.02;
      // 将点A和点B放置在球体表面
      pointA
        .sub(sphereCenter)
        .normalize()
        .multiplyScalar(sphereRadius)
        .add(sphereCenter);
      pointB
        .sub(sphereCenter)
        .normalize()
        .multiplyScalar(sphereRadius)
        .add(sphereCenter);
      const materialA = new THREE.MeshBasicMaterial({
        color: 0x5590ed,
        side: THREE.DoubleSide,
      });
      const materialB = new THREE.MeshBasicMaterial({
        color: 0x125d06,
        side: THREE.DoubleSide,
      });
      // / 为每个点创建几何体（以Mesh为例）
      const geometry = new THREE.SphereGeometry(0.03, 32, 32); // 假设圆点的半径是0.1
      const sphereA = new THREE.Mesh(geometry, materialA);
      const sphereB = new THREE.Mesh(geometry, materialB);
      // 设置每个点的位置
      sphereA.position.copy(pointA);
      sphereB.position.copy(pointB);
      // 计算球心到每个点的向量
      const normalA = pointA.clone().sub(sphereCenter).normalize();
      const normalB = pointB.clone().sub(sphereCenter).normalize();
      const up = new THREE.Vector3(0, 1, 0); // 用于计算旋转
      sphereA.quaternion.setFromUnitVectors(up, normalA);
      sphereB.quaternion.setFromUnitVectors(up, normalB);
      // 计算点A和点B的中点
      let midpoint = new THREE.Vector3()
        .addVectors(pointA, pointB)
        .multiplyScalar(0.5);
      // 计算从球心到中点的向量
      let sphereToMidpoint = new THREE.Vector3().subVectors(
        midpoint,
        sphereCenter
      );
      // 计算线段的长度来 设置时间

      let controlPoint = midpoint
        .clone()
        .add(sphereToMidpoint.normalize().multiplyScalar(arcHeight));
      sphereA.material.transparent = true;
      sphereA.material.opacity = 0;
      sphereA.scale.set(0, 0, 0);
      sphereB.material.transparent = true;
      sphereB.material.opacity = 0;
      sphereB.scale.set(0, 0, 0);
      if(!this.scene)return
      this.scene.add(sphereA);
      this.scene.add(sphereB);
      const curve = new THREE.QuadraticBezierCurve3(
        pointA,
        controlPoint,
        pointB
      );
      const points = curve.getPoints(50);
      const points2 = curve.getPoints(50);

      let lineLength = this.calculateLineLength(points);
      // 假设每单位长度的动画应该持续 0.1 秒
      const durationPerUnit = 0.4; // 可以根据需求调整这个值
      // 计算实线动画的持续时间
      const duration = lineLength * durationPerUnit;
      const geometryLine = new THREE.BufferGeometry().setFromPoints(points);
      const geometryLine2 = new THREE.BufferGeometry().setFromPoints(points2);
      const materialDashed = new THREE.LineDashedMaterial({
        color: 0x125d06,
        dashSize: 0.05,
        gapSize: 0.05,
        // dashOffset: 0.01, // 初始设置
      });
      const materialSolid = new THREE.LineBasicMaterial({
        color: 0x125d06,
        linewidth: 50,
      });
      const dashedLine = new THREE.Line(geometryLine, materialDashed);
      dashedLine.computeLineDistances();
      this.scene.add(dashedLine);
      const solidLine = new THREE.Line(geometryLine2, materialSolid);
      solidLine.visible = false;
      this.scene.add(solidLine);
      dashedLine.geometry.setDrawRange(0, 0);
      // GSAP动画来更新虚线
      let isHiddenDuration = 0.8;
      let isScaleDuration = 0.3;
      Tween.gsap.to(sphereB.material, {
        opacity: 1,
        duration: isHiddenDuration,
        ease: "power1.inOut",
      });
      Tween.gsap.to(sphereB.scale, {
        x: 1,
        y: 1,
        z: 1,
        duration: isScaleDuration,
        ease: "power1.inOut",
      });
      Tween.gsap.to(sphereA.scale, {
        x: 1,
        y: 1,
        z: 1,
        duration: isScaleDuration,
        ease: "power1.inOut",
      });

      Tween.gsap.to(sphereA.material, {
        opacity: 1,
        duration: isHiddenDuration,
        ease: "power1.inOut",
        onComplete: () => {
          Tween.gsap.to(dashedLine.geometry.drawRange, {
            count: points.length,
            duration: duration + 0.2,
            ease: "power1.inOut",
            onUpdate: () => {
              dashedLine.geometry.setDrawRange(
                0,
                dashedLine.geometry.drawRange.count
              );
              dashedLine.geometry.attributes.position.needsUpdate = true; // 更新位置信息
            },
            onComplete: () => {
              solidLine.geometry.setDrawRange(0, 0);
              solidLine.visible = true; // 显示实线
              // GSAP动画来更新实线
              Tween.gsap.to(solidLine.geometry.drawRange, {
                count: points2.length,
                duration: duration,
                ease: "power1.inOut",
                onUpdate: () => {
                  solidLine.geometry.setDrawRange(
                    0,
                    solidLine.geometry.drawRange.count
                  );
                  solidLine.geometry.attributes.position.needsUpdate = true;
                },
                onComplete: () => {
                  dashedLine.visible = false;
                  Tween.gsap.to(solidLine.geometry.drawRange, {
                    start: points.length, // 这里开始点设为points.length，终点始终保持不变
                    count: 0, // 结束点逐步减小到0
                    duration: duration,
                    ease: "power1.inOut",
                    onUpdate: () => {
                      solidLine.geometry.setDrawRange(
                        solidLine.geometry.drawRange.start,
                        points.length - solidLine.geometry.drawRange.start
                      );
                      solidLine.geometry.attributes.position.needsUpdate = true;
                    },
                    onComplete: () => {
                      // 这里可以加一些动画完成后的处理，比如隐藏线条等
                      solidLine.visible = false;
                      Tween.gsap.to(sphereB.material, {
                        opacity: 1,
                        duration: isHiddenDuration,
                        ease: "power1.inOut",
                      });
                      Tween.gsap.to(sphereB.scale, {
                        x: 0,
                        y: 0,
                        z: 0,
                        duration: isScaleDuration,
                        ease: "power1.inOut",
                      });
                      Tween.gsap.to(sphereA.scale, {
                        x: 0,
                        y: 0,
                        z: 0,
                        duration: isScaleDuration,
                        ease: "power1.inOut",
                      });
                      Tween.gsap.to(sphereA.material, {
                        opacity: 0,
                        duration: isScaleDuration,
                        ease: "power1.inOut",
                        onComplete: () => {
                          // 停止GSAP动画
                          try {
                            Tween.gsap.killTweensOf(sphereB.material);
                            Tween.gsap.killTweensOf(sphereB.scale);
                            Tween.gsap.killTweensOf(sphereA.material);
                            Tween.gsap.killTweensOf(sphereA.scale);
                            Tween.gsap.killTweensOf(
                              solidLine.geometry.drawRange
                            );
                            Tween.gsap.killTweensOf(
                              dashedLine.geometry.drawRange
                            );

                            // 从场景中移除物体
                            this.scene.remove(sphereA);
                            this.scene.remove(sphereB);
                            this.scene.remove(solidLine);
                            this.scene.remove(dashedLine);

                            // 清理材质
                            materialA.dispose();
                            materialB.dispose();
                            materialDashed.dispose();
                            materialSolid.dispose();

                            // 清理几何体
                            geometry.dispose();
                            geometryLine.dispose();
                            geometryLine2.dispose();
                            // 清理纹理（如果存在的话）
                            // 清除对对象的引用，这有助于垃圾回收器释放它们
                            sphereA = null;
                            sphereB = null;
                            solidLine = null;
                            dashedLine = null;
                          } catch (error) {}
                        },
                      });
                    },
                  });
                },
              });
            },
          });
        },
      });
    },
    // addNormalMap  法线 贴图
    addNormalMap() {
      // 创建一个纹理加载器
      // jinshu
      const loader = new THREE.TextureLoader();
      let mesh = this.objs.Mesh["Sphere"];
      // let map = loader.load("/model/textures/map.jpg");
      // let emissionTexture = loader.load("/model/textures/GS Emission.jpg");
      let normalMapTexture = loader.load("/model/textures/GS Normal.jpg");
      // let roughnessTexture = loader.load("/model/textures/GS Roughness.jpg");
      // this.heightTexture = loader.load("/model/textures/GS Height.jpg");
      // 应用该材质到对象上
      mesh.material.normalMap = normalMapTexture;
      // mesh.material.map = map;
      // mesh.material.emissiveMap = emissionTexture;
      // mesh.material.roughnessMap = roughnessTexture;
      // mesh.material.emissive = new THREE.Color(0xffffff); // 设置一个基础的发光颜色
      // mesh.material.emissiveIntensity = 0.5; // 设置发光的强度
      // mesh.material.side = THREE.DoubleSide;
    },
    focusObject3d(objs, option) {
      let defaultOption = {
        offset: new THREE.Vector3(0, 0.045, 0.01),
        scale: 0.33,
        duration: 1,
      };

      let option1 = option || {};

      let opt = {};
      Object.assign(opt, defaultOption, option1);

      let { offset, scale, duration } = opt;

      if (!objs.isMesh && !objs.isGroup && !Array.isArray(objs)) {
        console.log(
          `focusObject3d error : {objs}:${objs} is not mesh, group or array!`
        );
        return new Promise((r) => {
          r();
        });
      }

      let box = new THREE.Box3();
      if (Array.isArray(objs)) {
        for (let o of objs) {
          o.updateMatrixWorld();
          box.expandByObject(o);
        }
      } else {
        objs.updateMatrixWorld();
        box.setFromObject(objs);
      }
      let diagonal = box.min.distanceTo(box.max);
      let target = new THREE.Vector3();
      box.getCenter(target);
      // let offset = new THREE.Vector3(-1, 0.1, 0);
      offset.normalize();
      let cameraZoom = diagonal / 2 / scale;
      offset.multiplyScalar(cameraZoom);
      let position = target.clone().add(offset);
      return Commen.go_cameraV(
        target,
        position,
        this.controls,
        this.camera,
        duration,
        0
      );
    },

    reset_camera: function () {
      return new Promise((res, rej) => {
        Commen.go_camera(
          -0,
          -0,
          0,
          0.02,
          12.156,
          -13.949,
          this.controls,
          this.camera,
          0,
          0
        );
        res();
      });
    },

    area3DLabel(option) {
      let optionRs = {};
      let defaultOption = {
        size: 10,
        flag: false,
        color: { r: 255, g: 255, b: 255, a: 1 },
        backgroundFill: false,
      };

      Object.assign(optionRs, defaultOption, option);
      let { pos, content, size, normal, color, backgroundFill } = optionRs;

      let loader = new THREE.TextureLoader();
      loader.needsUpdate = true;

      let num = Commen.strLen(content);

      let canvasHeight = 200;
      let canvasWidth = 1000;
      let canvasRadio = canvasWidth / canvasHeight;

      let fontsize = 35;
      let wordHeight = (35 / canvasHeight) * size;
      let wordWidth = num * wordHeight;

      let centerY = (1 - 2 / canvasRadio) / 2 + 0.01;

      let sprite_word = Commen.makeTextSprite(content, {
        fontsize: fontsize,
        fontface: "STFangsong",
        backgroundColor: color,
        backgroundFill: backgroundFill,
      });

      let plane = new THREE.PlaneGeometry(wordWidth, wordHeight);
      let mat = new THREE.MeshBasicMaterial({
        map: sprite_word.material.map,
        depthWrite: false,
        transparent: true,
        opacity: 1,
      });
      let mesh = new THREE.Mesh(plane, mat);

      let eye = new THREE.Vector3();
      let dir = normal.clone();
      let up = new THREE.Vector3(0, 1, 0);
      let mx4 = new THREE.Matrix4().lookAt(eye, dir, up);
      let qt = new THREE.Quaternion().setFromRotationMatrix(mx4);
      mesh.quaternion.copy(qt);
      mesh.position.copy(pos);

      // sprite_word.center = new THREE.Vector2(0.5, centerY);
      // sprite_word.scale.copy(new THREE.Vector3(wordHeight * 5, wordHeight, 0.05))
      // sprite_word.position.copy(pos)

      let group = new THREE.Group();
      group.add(mesh);
      return group;
    },

    imgLabel(
      pos1,
      img = "static/textures/hint/point3_1.png",
      newName,
      fig,
      size = 1.5,
      flag = false
    ) {
      let { x, y, z } = pos1;
      let loader = new THREE.TextureLoader();
      loader.needsUpdate = true;
      let hint1;
      // let size = 10;
      let pos = new THREE.Vector3(x, y, z);
      let spriteMap = loader.load(img);
      let mat = new THREE.SpriteMaterial({
        map: spriteMap,
        // depthWrite: false,
        transparent: true,
        depthTest: false,
        // opacity: 0.7,
      });
      hint1 = new THREE.Sprite(mat);
      hint1.name2 = newName;
      hint1.fig = fig;
      hint1.center = new THREE.Vector2(0.5, 0);
      hint1.scale.copy(new THREE.Vector3(size - 0.05, size + 0.4, 0.05));
      hint1.position.copy(pos);
      let group = new THREE.Group();
      group.add(hint1);
      hint1.sceneStateFlag = flag;
      return group;
    },
    //css2D标签
    addcss2d() {
      return new Promise((res, rej) => {
        //创建css2d
        const initcss2d = (center, id) => {
          let content = `<div style="min-width:3vh; min-height:5vh;
          width: 44vh;
          height: 7.5vh;
          box-sizing: border-box;
          background: url(${fuchuang}) center center no-repeat;
          background-size: 50% 100%;
          background-position: left;
          color: #fff;
          font-size: 1.8vh;
          font-family: SimHei;
          padding:0.7vh 0 0 2vh;
          pointer-events: none;">
            ${id}
          </div>`;
          let valuesDiv = document.createElement("div");

          valuesDiv.innerHTML = content;

          valuesDiv = valuesDiv.firstChild;

          let valueCss2dObj = new CSS2DObject(valuesDiv); // valueCss2dObj.sceneStateFlag = [SCENESTATE.CARNOW];
          if (id.includes("内蒙")) {
            valueCss2dObj.position.set(center.x - 10, center.y, center.z + 7);
          } else if (id.includes("新疆")) {
            valueCss2dObj.position.set(center.x + 5, center.y, center.z);
          } else {
            valueCss2dObj.position.set(center.x - 2, center.y + 17, center.z);
          }

          valueCss2dObj.css2dId = id;

          valueCss2dObj.element.id = id; // valueCss2dObj.position.set() // this.objs.Group[e].add(valueCss2dObj); // e.add(valueCss2dObj);

          // valueCss2dObj.visible = false;

          // this.css2dGroup.add(valueCss2dObj); // console.log(this.css2dGroup); // this.valueCss2dObj = valueCss2dObj;
          this.css2dGroup.add(valueCss2dObj);
        };
        this.css2dGroup = new THREE.Group();
        this.scene.add(this.css2dGroup);
        let arr = [
          {
            mesh: "xj_d001",
            name: "新疆6家养殖基地",
          },
          {
            mesh: "nmg_d001",
            name: "内蒙36家养殖基地",
          },
          {
            mesh: "sd_d002",
            name: "山东13家养殖基地",
          },
          {
            mesh: "sc_d001",
            name: "四川8家养殖基地",
          },
          {
            mesh: "hhn_d001",
            name: "湖南6家养殖基地",
          },
          {
            mesh: "yn_d001",
            name: "云南12家养殖基地",
          },
        ];
        arr.forEach((item) => {
          // if (this.objs.Group[item]) {
          let box = new THREE.Box3().setFromObject(this.objs.Mesh[item.mesh]);
          let center = new THREE.Vector3();
          box.getCenter(center);
          initcss2d(center, item.name);
          // } else {
          //   return;
          // }
        });

        // }
        // }
        res();
      });
    },
    shiCss2d(index) {
      return new Promise((res, rej) => {
        //创建css2d
        const initcss2d = (center, id, idx) => {
          let content = `<div style="min-width:3vh; min-height:5vh;
          width:${index == idx ? "9vh" : " 4vh"};
          height: ${index == idx ? "12vh" : " 6vh"};
          box-sizing: border-box;
          background: url(${
            index == idx
              ? "../../../static/textures/hint/hide2Click.png"
              : "../../../static/textures/hint/hide2.png"
          }) center center no-repeat;
          background-size: 100% 100%;
          background-position: left;
          color: #fff;
          font-size: 1.8vh;
          font-family: SimHei;
          padding:0.7vh 0 0 2vh;
          pointer-events: none;">
          </div>`;
          let valuesDiv = document.createElement("div");

          valuesDiv.innerHTML = content;

          valuesDiv = valuesDiv.firstChild;

          let valueCss2dObj = new CSS2DObject(valuesDiv); // valueCss2dObj.sceneStateFlag = [SCENESTATE.CARNOW];
          if (id.includes("内蒙")) {
            valueCss2dObj.position.set(center.x - 10, center.y - 10, center.z);
          } else if (id.includes("新疆")) {
            valueCss2dObj.position.set(center.x + 5, center.y, center.z);
          } else {
            valueCss2dObj.position.set(center.x, center.y, center.z);
          }

          valueCss2dObj.css2dId = id;

          valueCss2dObj.element.id = id; // valueCss2dObj.position.set() // this.objs.Group[e].add(valueCss2dObj); // e.add(valueCss2dObj);

          // valueCss2dObj.visible = false;

          // this.css2dGroup.add(valueCss2dObj); // console.log(this.css2dGroup); // this.valueCss2dObj = valueCss2dObj;
          this.shiCss2dGroup.add(valueCss2dObj);
        };
        this.shiCss2dGroup = new THREE.Group();
        this.scene.add(this.shiCss2dGroup);
        let arr = [
          {
            position: new THREE.Vector3(43.049, 12.828, -20.031),
            name: "临沂",
          },
          {
            position: new THREE.Vector3(45.562, 12.828, -21.669),
            name: "胶南",
          },
          {
            position: new THREE.Vector3(51.871, 12.828, -27.104),
            name: "烟台",
          },
          {
            position: new THREE.Vector3(41.32, 13.15, -29.261),
            name: "滨州",
          },
          {
            position: new THREE.Vector3(39.963, 12.828, -23.226),
            name: "泰安",
          },
          {
            position: new THREE.Vector3(48.294, 13.15, -25.974),
            name: "平度",
          },
          {
            position: new THREE.Vector3(43.787, 12.828, -24.537),
            name: "潍坊",
          },
        ];
        arr.forEach((item, idx) => {
          // if (this.objs.Group[item]) {
          // let box = new THREE.Box3().setFromObject(this.objs.Mesh[item.mesh]);
          // let center = new THREE.Vector3();
          // box.getCenter(center);
          initcss2d(item.position, item.name, idx);
          // } else {
          //   return;
          // }
        });

        // }
        // }
        res();
      });
    },
    css2dLabel(content, position) {
      let valuesDiv = document.createElement("div");
      valuesDiv.innerHTML = content;
      valuesDiv = valuesDiv.firstChild;
      let valueCss2dObj = new CSS2DObject(valuesDiv);
      valueCss2dObj.position.copy(position);
      return valueCss2dObj;
    },
    initEvent: function () {
      this.eventHandler = new EventHandler(this);
      // this.eventHandler.enableMouseMove([this.tunnelModels]);
      this.eventHandler.enableMouseMove(this.billboards);
      this.eventHandler.addEventListener("move", (e) => {
        let intersects = e.intersects;
      });
      this.eventHandler.addEventListener("click", (e) => {
        let intersects = e.intersects;
        if (intersects.length == 0) return;
      });
    },
    initbloom: function () {
      this.composer = new EffectComposer(this.renderer);
      let renderScene = new RenderPass(this.scene, this.camera);
      this.composer.addPass(renderScene);
      this.bloomPass = new UnrealBloomPass(
        new THREE.Vector2(
          this.container.clientWidth,
          this.container.clientHeight
        ),
        0.5,
        0.1,
        5
      );
      this.bloomPass.threshold = this.params.bloomThreshold;
      this.bloomPass.strength = this.params.bloomStrength;
      this.bloomPass.radius = this.params.bloomRadius;
      this.bloomPass.transparent = true;
      // this.bloomPass.depthTest = false
      this.composer.renderToScreen = false;
      this.composer.addPass(this.bloomPass);
      this.outlinePass = new OutlinePass(
        new THREE.Vector2(
          this.container.clientWidth,
          this.container.clientHeight
        ),
        this.scene,
        this.camera
      );
      this.composer.addPass(this.outlinePass);

      this.outlinePass1 = new OutlinePass(
        new THREE.Vector2(
          this.container.clientWidth,
          this.container.clientHeight
        ),
        this.scene,
        this.camera
      );
      this.composer.addPass(this.outlinePass1);

      this.outlinePass2 = new OutlinePass(
        new THREE.Vector2(
          this.container.clientWidth,
          this.container.clientHeight
        ),
        this.scene,
        this.camera
      );
      this.composer.addPass(this.outlinePass2);

      this.outlinePass3 = new OutlinePass(
        new THREE.Vector2(
          this.container.clientWidth,
          this.container.clientHeight
        ),
        this.scene,
        this.camera
      );
      this.composer.addPass(this.outlinePass3);
      this.outlinePass4 = new OutlinePass(
        new THREE.Vector2(
          this.container.clientWidth,
          this.container.clientHeight
        ),
        this.scene,
        this.camera
      );
      //this.composer.addPass(this.outlinePass3);
      this.outlinePass.edgeStrength = 10;
      this.outlinePass.edgeGlow = 1;
      this.outlinePass.edgeThickness = 1;
      this.outlinePass.pulsePeriod = 0;
      this.outlinePass.visibleEdgeColor = new THREE.Color(0x0050f7);
      this.outlinePass.hiddenEdgeColor = new THREE.Color(0x0050f7);
      this.outlinePass.id = 1;

      this.outlinePass1.edgeStrength = 3;
      this.outlinePass1.edgeGlow = 1;
      this.outlinePass1.edgeThickness = 1;
      this.outlinePass1.pulsePeriod = 2;
      this.outlinePass1.visibleEdgeColor = new THREE.Color(0x00ff00);
      this.outlinePass1.hiddenEdgeColor = new THREE.Color(0x00ff00);
      this.outlinePass1.id = 2;

      this.outlinePass2.edgeStrength = 3;
      this.outlinePass2.edgeGlow = 1;
      this.outlinePass2.edgeThickness = 1;
      this.outlinePass2.pulsePeriod = 2;
      this.outlinePass2.visibleEdgeColor = new THREE.Color(0xff0000);
      this.outlinePass2.hiddenEdgeColor = new THREE.Color(0xff0000);
      this.outlinePass2.id = 3;

      this.outlinePass3.edgeStrength = 3;
      this.outlinePass3.edgeGlow = 2;
      this.outlinePass3.edgeThickness = 2;
      this.outlinePass3.pulsePeriod = 2;
      this.outlinePass3.visibleEdgeColor = new THREE.Color(0xfff600);
      this.outlinePass3.hiddenEdgeColor = new THREE.Color(0xfff600);
      this.outlinePass3.id = 4;

      this.outlinePass4.edgeStrength = 5;
      this.outlinePass4.edgeGlow = 1.5;
      this.outlinePass4.edgeThickness = 1.5;
      this.outlinePass4.pulsePeriod = 2;
      this.outlinePass4.visibleEdgeColor = new THREE.Color(0x18ce99);
      this.outlinePass4.hiddenEdgeColor = new THREE.Color(0x18ce99);
      this.outlinePass4.id = 5;

      this.effectFXAA = new ShaderPass(FXAAShader);
      this.effectFXAA.uniforms["resolution"].value.set(
        1 / this.container.clientWidth,
        1 / this.container.clientHeight
      );
      this.effectFXAA.renderToScreen = true;
      // this.uniforms.amount.value = 0.2;
      this.composer.addPass(this.effectFXAA);

      let vertexShader = `
				varying vec2 vUv;
				void main() {
					vUv = uv;
					gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
				}
			`;

      let fragmentshader = `
				uniform sampler2D baseTexture;
				uniform sampler2D bloomTexture;
				varying vec2 vUv;
				void main() {
					gl_FragColor = ( texture2D( baseTexture, vUv ) + vec4( 1.0 ) * texture2D( bloomTexture, vUv ) );
				}
			`;

      let finalPass = new ShaderPass(
        new THREE.ShaderMaterial({
          uniforms: {
            baseTexture: { value: null },
            bloomTexture: { value: this.composer.renderTarget2.texture },
          },
          vertexShader: vertexShader,
          fragmentShader: fragmentshader,
          defines: {},
        }),
        "baseTexture"
      );
      finalPass.needsSwap = true;

      this.finalComposer = new EffectComposer(this.renderer);
      this.finalComposer.addPass(renderScene);
      this.finalComposer.addPass(finalPass);
    },

    darkenNonBloomed(obj) {
      if (
        (obj.isMesh || obj.isLineSegments) &&
        this.bloomLayer.test(obj.layers) === false
      ) {
        this.materials[obj.uuid] = obj.material;
        obj.material = this.darkMaterial;
      } else if (obj.isSprite && this.bloomLayer.test(obj.layers) === false) {
        this.materials[obj.uuid] = obj.material;
        obj.material = this.darkMaterial_t;
      }
    },

    restoreMaterial(obj) {
      if (this.materials[obj.uuid]) {
        obj.material = this.materials[obj.uuid];
        delete this.materials[obj.uuid];
      }
    },

    updateProgress: function (per) {
      this.allPer += per;
      let t = (this.allPer * 100).toFixed(2) + "%";
      if ("100.00%" == t) {
        $(".loading").fadeOut(1000);
        setTimeout(() => {
          // this.active_sel = [this.scene];
          this.reset_camera();
        }, 0);
      }
    },
    animate: function () {
      this.requestId = requestAnimationFrame(this.animate);
      this.controls.update();
      this.render();
      this.scene.traverse(this.darkenNonBloomed);
      this.scene.background = new THREE.Color(0x5dcbe3);
      this.composer.render();
      this.scene.traverse(this.restoreMaterial);
      this.finalComposer.render();
    },

    render: function () {
      let delta = this.clock.getDelta();
      for (let j = 0; j < this.gif.length; j++) {
        if (this.gif[j]) this.gif[j].update(200 * delta);
      }
      // if (this.effectMaterial1_show == true) {
      //   this.effectMaterial1.map.offset.y += 0.0015;
      // }
      if (this.info_show) {
        this.updateScreenPosition_info();
      }
      if (this.info_show2) {
        this.updateScreenPosition_info2();
      }
      if (this.hover_show) {
        this.updateScreenPosition_hover();
      }
      if (this.effectmats) {
        for (let i = 0; i < this.effectmats.length; i++) {
          this.effectmats[i].map.offset.x += 0.002;
        }
      }
      if (this.circleAni) {
        this.circleAni.render();
      }
      this.renderer.render(this.scene, this.camera);
      this.css2DRenderer.render(this.scene, this.camera);
    },

    updateScreenPosition_info() {
      //let vector=this.choose_sp.position.clone();
      let vector = this.choose_sp.clone();
      vector.project(this.camera);
      vector.x = Math.round(
        (0.5 + vector.x / 2) *
          (this.container.clientWidth / window.devicePixelRatio)
      );
      vector.y = Math.round(
        (0.5 - vector.y / 2) *
          (this.container.clientHeight / window.devicePixelRatio)
      );
      // 适配小屏幕  单位转成vh
      let vectorY = (vector.y / window.innerHeight) * 100;
      let vectorX = (vector.x / window.innerHeight) * 100;
      if (this.choose_id1 == "内蒙") {
        $(".infobox").css("top", vectorY - 5 + "vh");
      } else {
        $(".infobox").css("top", vectorY - 15 + "vh");
      }

      $(".infobox").css("left", vectorX - 38 + "vh");
    },
    updateScreenPosition_info2() {
      //let vector=this.choose_sp.position.clone();
      let vector = this.choose_sp2.clone();
      vector.project(this.camera);
      vector.x = Math.round(
        (0.5 + vector.x / 2) *
          (this.container.clientWidth / window.devicePixelRatio)
      );
      vector.y = Math.round(
        (0.5 - vector.y / 2) *
          (this.container.clientHeight / window.devicePixelRatio)
      );
      // 适配小屏幕  单位转成vh
      let vectorY = (vector.y / window.innerHeight) * 100;
      let vectorX = (vector.x / window.innerHeight) * 100;
      if (this.choose_id == "滨州") {
        $(".infobox2").css("top", vectorY - 17 + "vh");
      } else {
        $(".infobox2").css("top", vectorY - 25 + "vh");
      }

      $(".infobox2").css("left", vectorX - 28 + "vh");
    },

    updateScreenPosition_hover() {
      //let vector=this.choose_sp.position.clone();
      let vector = this.hover_sp.clone();
      vector.project(this.camera);
      vector.x = Math.round(
        (0.5 + vector.x / 2) *
          (this.container.clientWidth / window.devicePixelRatio)
      );
      vector.y = Math.round(
        (0.5 - vector.y / 2) *
          (this.container.clientHeight / window.devicePixelRatio)
      );
      let t = $(".hover_name").height();
      $(".hover_name").css("top", vector.y - t * 2 - 10 + "px");
      $(".hover_name").css("left", vector.x + 10 + "px");
    },

    initBackground0: function () {
      THREE.DefaultLoadingManager.onLoad = function () {
        //pmremGenerator.dispose();
      };
      // let ldrUrls = [ 'px.png', 'nx.png', 'py.png', 'ny.png', 'pz.png', 'nz.png' ];
      let ldrUrls = [
        "px.jpg",
        "nx.jpg",
        "py.jpg",
        "ny.jpg",
        "pz.jpg",
        "nz.jpg",
      ];
      this.ldrCubeMap0 = new THREE.CubeTextureLoader()
        .setPath("/public/textures/cube/cube_sky/") //Park3Med
        .load(ldrUrls, () => {
          //this.ldrCubeMap0.encoding = THREE.GammaEncoding;
          //this.ldrCubeMap0.encoding = THREE.sRGBEncoding;
          // this.theldrCubeMap = this.ldrCubeMap0;
        });
    },

    initBackground1: function () {
      // let ldrUrls = [ 'px.png', 'nx.png', 'py.png', 'ny.png', 'pz.png', 'nz.png' ];
      let ldrUrls = [
        "px.jpg",
        "nx.jpg",
        "py.jpg",
        "ny.jpg",
        "pz.jpg",
        "nz.jpg",
      ];
      this.ldrCubeMap1 = new THREE.CubeTextureLoader()
        .setPath("textures/cube/cube_night/") //Park3Med
        .load(ldrUrls, () => {
          //this.ldrCubeMap0.encoding = THREE.GammaEncoding;
          //this.ldrCubeMap0.encoding = THREE.sRGBEncoding;
          // this.scene.background = this.ldrCubeMap0;
          this.theldrCubeMap = this.ldrCubeMap1;
        });
    },

    initBackground: function () {
      return new Promise((res, rej) => {
        res();
      });
    },
    initBackground6: function () {
      return new Promise((res, rej) => {
        THREE.DefaultLoadingManager.onLoad = function () {
          //pmremGenerator.dispose();
        };
        //let ldrUrls = [ 'px.png', 'nx.png', 'py.png', 'ny.png', 'pz.png', 'nz.png' ];
        let ldrUrls = [
          "px.jpg",
          "nx.jpg",
          "py.jpg",
          "ny.jpg",
          "pz.jpg",
          "nz.jpg",
        ];
        this.ldrCubeMap6 = new THREE.CubeTextureLoader()
          .setPath("/textures/cube/Park3Med/") //Park3Med
          .load(ldrUrls, () => {
            //this.ldrCubeMap.encoding = THREE.GammaEncoding;
            this.ldrCubeMap6.encoding = THREE.sRGBEncoding;
            //this.ldrCubeRenderTarget = pmremGenerator.fromCubemap( this.ldrCubeMap );
            // this.scene.background = this.ldrCubeMap;
            res();
          });
      });
    },
    addfloor: function () {
      let planeScale = 2;

      let defaultHeight = 0;
      let geometry_Mirror = new THREE.PlaneBufferGeometry(
        100 * planeScale,
        100 * planeScale
      );
      var groundMirror = new Reflector(geometry_Mirror, {
        clipBias: 0.003,
        textureWidth: window.innerWidth * window.devicePixelRatio,
        textureHeight: window.innerHeight * window.devicePixelRatio,
      });
      groundMirror.position.set(0, defaultHeight + 0.01, 0);
      groundMirror.rotateX(-Math.PI / 2);
      groundMirror.name = "Mirror";
      groundMirror.material.transparent = true;

      // groundMirror.material.blending = THREE.NoBlending;
      this.group_0.add(groundMirror);
      // let planeGeometry = new THREE.PlaneGeometry(
      //   400 * planeScale,
      //   400 * planeScale,
      //   1,
      //   1
      // );
      // let texture = new THREE.TextureLoader().load("textures/yuan.png");
      // texture.wrapS = THREE.RepeatWrapping;
      // texture.wrapT = THREE.RepeatWrapping;
      // texture.repeat.set(1, 1);
      // let meshMaterial = new THREE.MeshBasicMaterial({
      //   transparent: true,
      //   opacity: 0.5,
      //   map: texture,
      //   depthWrite: false,
      // });
      // let plane = new THREE.Mesh(planeGeometry, meshMaterial);
      // plane.position.set(0, defaultHeight - 2.2, 0);
      // plane.rotateX(-Math.PI / 2);
      // plane.name = "floor";
      // this.model_floor.push(plane);
      // this.group_0.add(plane);

      // planeGeometry = new THREE.PlaneGeometry(400 * planeScale, 400 * planeScale, 1, 1);
      // texture = new THREE.TextureLoader().load("textures/yuan1.png");
      // texture.wrapS = THREE.RepeatWrapping;
      // texture.wrapT = THREE.RepeatWrapping;
      // texture.repeat.set(1, 1);
      // meshMaterial = new THREE.MeshBasicMaterial({
      //   transparent: true,
      //   opacity: 0.2,
      //   map: texture,
      //   depthWrite: false,
      // });
      // plane = new THREE.Mesh(planeGeometry, meshMaterial);
      // plane.position.set(0, defaultHeight - 2.4, 0);
      // plane.rotateX(-Math.PI / 2);
      // plane.name = "floor";
      // this.model_floor.push(plane);
      // this.group_0.add(plane);

      // planeGeometry = new THREE.PlaneGeometry(700 * planeScale, 700 * planeScale, 1, 1);
      // texture = new THREE.TextureLoader().load("textures/yuan2.png");
      // texture.wrapS = THREE.RepeatWrapping;
      // texture.wrapT = THREE.RepeatWrapping;
      // texture.repeat.set(1, 1);
      // meshMaterial = new THREE.MeshBasicMaterial({
      //   transparent: true,
      //   opacity: 0.2,
      //   map: texture,
      //   depthWrite: false,
      // });
      // plane = new THREE.Mesh(planeGeometry, meshMaterial);
      // plane.position.set(0, defaultHeight - 2.6, 0);
      // plane.rotateX(-Math.PI / 2);
      // plane.name = "floor";
      // this.model_floor.push(plane);
      // this.group_0.add(plane);

      // planeGeometry = new THREE.PlaneGeometry(700 * planeScale, 700 * planeScale, 1, 1);
      // texture = new THREE.TextureLoader().load("textures/yuan3.png");
      // texture.wrapS = THREE.RepeatWrapping;
      // texture.wrapT = THREE.RepeatWrapping;
      // texture.repeat.set(1, 1);
      // meshMaterial = new THREE.MeshBasicMaterial({
      //   transparent: true,
      //   opacity: 0.1,
      //   map: texture,
      //   depthWrite: false,
      // });
      // plane = new THREE.Mesh(planeGeometry, meshMaterial);
      // plane.position.set(0, defaultHeight - 2.8, 0);
      // plane.rotateX(-Math.PI / 2);
      // plane.name = "floor";
      // //this.model_floor.push(plane);
      // //this.group_0.add(plane);

      // //底面
      // let repeat = 2000;
      // planeGeometry = new THREE.PlaneGeometry(repeat * 10, repeat * 10, 1, 1);
      // texture = new THREE.TextureLoader().load("textures/pt.png");
      // texture.wrapS = THREE.RepeatWrapping;
      // texture.wrapT = THREE.RepeatWrapping;
      // texture.repeat.set(repeat, repeat);
      // meshMaterial = new THREE.MeshBasicMaterial({
      //   transparent: true,
      //   opacity: 0.2,
      //   map: texture,
      //   depthWrite: false,
      // });
      // plane = new THREE.Mesh(planeGeometry, meshMaterial);
      // plane.position.set(0, defaultHeight - 3, 0);
      // plane.rotateX(-Math.PI / 2);
      // plane.name = "floor";
      // this.group_0.add(plane);
    },

    addeffect: function () {
      let effectScale = 150;

      let effectMaterial = new THREE.MeshBasicMaterial({
        transparent: true,
        depthWrite: false,
        side: THREE.DoubleSide,
        opacity: 0.2,
      });

      let geometry = new THREE.CylinderGeometry(
        0.00015 * effectScale,
        0.00015 * effectScale,
        0.3 * effectScale,
        4,
        1
      );
      let effectMesh;

      for (let i = 0; i < 50; i++) {
        let effectMap = new THREE.TextureLoader().load(
          "static/textures/lightFlow_strip07.png"
        );
        effectMap.wrapS = THREE.RepeatWrapping;
        effectMap.wrapT = THREE.RepeatWrapping;
        effectMap.rotation = Math.PI / 2;

        let mt = effectMaterial.clone();
        this.effectmats.push(mt);
        mt.map = effectMap;

        effectMesh = new THREE.Mesh(geometry, mt);
        let x = Math.round((Math.random() - 0.5) * effectScale);
        let z = Math.round((Math.random() - 0.5) * effectScale);
        effectMesh.position.set(x, 30, z);
        let e = Math.random();
        mt.map.offset.x = e;
        effectMesh.layers = this.layers_1;
        effectMesh.name = "flyline";
        this.group_0.add(effectMesh);
      }
    },

    addcircle() {
      let mt1 = getMaterial_circle(1, new THREE.Color(0x22dd77)); //绿
      let mt2 = getMaterial_circle(1, new THREE.Color(0x00b1ff)); //蓝
      let mt3 = getMaterial_circle(1, new THREE.Color(0xfcff00)); //黄
      let mt4 = getMaterial_circle(0, new THREE.Color(0xff0000)); //红

      let mats = [mt1, mt2, mt3, mt4];
      for (let mat of mats) {
        Tween.gsap.to(mat.uniforms.time, {
          value: 1,
          duration: 50,
          ease: "none",
          repeat: -1,
        });
      }

      let that = this;

      add_1circle(new THREE.Vector3(7.987, 0.1, -25.208), 150, mt2);
      // add_1circle(77396.75625244445, 22516.585339294434, 5, mt2);
      // add_1circle(77428.28635022223, 22511.346993375606, 5, mt3);
      // add_1circle(77408.72749511112, 22508.217529184767, 5, mt4);

      function getMaterial_circle(type = 0, color) {
        var ringShield = {
          uniforms: {
            color: {
              type: "c",
              value: color,
            },
            time: {
              type: "f",
              value: -1.5,
            },
            type: {
              type: "f",
              value: type || 0,
            },
            num: {
              type: "f",
              value: 4,
            },
          },
          vertexShaderSource: `
                    varying vec2 vUv;
                    void main(){
                            vUv = uv;
                            gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
                    }`,
          fragmentShaderSource: `
                    uniform float time;
                    uniform vec3 color;
                    uniform float type;
                    uniform float num;
                    varying vec2 vUv;
                    void main(){
                        float alpha = 1.0;
                        float dis = distance(vUv,vec2(0.5));//0-0.5
                        if(dis > 0.5){
                            discard;
                        }
                        if(type ==0.0){
                                float y = (sin(6.0 * num *(dis-time)) + 1.0)/2.0;
                            alpha = smoothstep(1.0,0.0,abs(y-0.5)/0.5) * (0.5 -dis) * 2.;
                        }else if(type ==1.0){
                                float step = fract(time* 4.)* 0.5;
                            if(dis<step){
                                    // alpha = smoothstep(1.0,0.0,abs(step-dis)/0.15);
                                alpha =1.- abs(step-dis)/0.15;
                            }else{
                                    alpha = smoothstep(1.0,0.0,abs(step-dis)/0.05);
                            }
                            alpha *= (pow((0.5 -dis)* 3.0,2.0));
                        }
                        gl_FragColor = vec4(color,alpha*0.15 );
                    }`,
        };
        let material = new THREE.ShaderMaterial({
          uniforms: ringShield.uniforms,
          defaultAttributeValues: {},
          vertexShader: ringShield.vertexShaderSource,
          fragmentShader: ringShield.fragmentShaderSource,
          blending: THREE.AdditiveBlending,
          depthWrite: !1,
          depthTest: !0,
          side: THREE.DoubleSide,
          transparent: !0,
          fog: !0,
        });
        return material;
      }

      function add_1circle(pos, r, mt) {
        let geometry = new THREE.RingBufferGeometry(0.001, r, 20);
        let mesh = new THREE.Mesh(geometry, mt);
        mesh.position.copy(pos);
        mesh.rotateX(-Math.PI / 2);
        that.scene.add(mesh);
        //mesh.layers = this.layers_1;
      }
    },

    //透明
    transparency_group(group, opacity = 0.1) {
      let that = this;
      group.traverse((child) => {
        if (child.isMesh || child.isSprite) {
          let m = child.material;
          if (Array.isArray(m)) {
            let mats = [];
            for (let item of m) {
              mats.push(changeMaterial(item));
            }
            child.material = mats;
          } else {
            child.material = changeMaterial(m);
          }
        } else if (child.isLineSegments) {
          child.layers = this.layers_hide;
        }
      });

      function changeMaterial(mat) {
        if (mat.changed) {
          return mat;
        }
        if (!that.materialStore) that.materialStore = {};
        that.materialStore[mat.id] = mat;
        let mat1 = mat.clone();
        mat1.changed = true;
        mat1.depthWrite = false;
        mat1.transparent = true;
        mat1.opacity = opacity;
        mat1.oldId = mat.id;
        return mat1;
      }
    },

    //不透明
    opacity_group(group) {
      let that = this;
      group.traverse((child) => {
        if (child.isMesh || child.isSprite || child.isLineSegments) {
          let m = child.material;
          if (Array.isArray(m)) {
            let mats = [];
            for (let item of m) {
              mats.push(resetMaterial(item));
            }
            child.material = mats;
          } else {
            child.material = resetMaterial(m);
          }
        }
      });

      function resetMaterial(mat) {
        let rs = mat;
        if (mat.oldId || mat.oldId == 0) {
          rs = that.materialStore[mat.oldId] || mat;
        }
        return rs;
      }
    },

    bloom_group: function (g) {
      return new Promise((res, rej) => {
        g.traverse((child) => {
          if (child.isMesh || child.isSprite || child.isLineSegments) {
            child.layers = this.layers_1;
            if ("hz" == child.material.name) {
              child.material.depthTest = false;
              child.material.depthWrite = false;
            }
          }
        });
        res(null);
      });
    },

    delete_group: function (group) {
      return new Promise((res, rej) => {
        if (group) {
          let arr = group.children.filter((x) => x);
          for (let a of arr) {
            this.dispose(group, a);
          }
        }
        res(null);
      });
    },

    close_page: function () {
      try {
        clearInterval(this.time1);
        clearInterval(this.time2);
        clearInterval(this.time3);
        clearInterval(this.time4);
        clearInterval(this.time5);
        clearInterval(this.time6);
        clearInterval(this.time7);
      } catch (e) {
        console.log(e);
      }
      window.removeEventListener("resize", this.onWindowResize, false);
      window.removeEventListener("mousemove", this.onMouseMove);
      this.renderer.domElement.removeEventListener(
        "pointerdown",
        this.onMouseDown,
        false
      );
      this.renderer.domElement.removeEventListener(
        "pointerup",
        this.onMouseUp,
        false
      );
      window.removeEventListener("dblclick", this.onDblClick);
      let arr = this.scene.children.filter((x) => x);
      arr.forEach((a) => {
        this.dispose(this.scene, a);
      });

      this.scene.remove();
      this.renderer.renderLists.dispose();
      this.renderer.dispose();
      this.renderer.forceContextLoss();
      this.renderer.content = null;
      this.renderer.domElement = null;
      this.renderer = null;
      window.cancelAnimationFrame(this.requestId);
      this.raycaster = null;
      this.camera = null;
      this.controls = null;
      this.composer = null;

      //
      this.scene = null;
      this.group_0 = null;
      this.group_1 = null;
      this.group_h = null;
      this.group_h2 = null;
      this.gif = null;
      this.point_texture = null;
      
    },

    dispose: function (parent, child) {
      if (child.children.length) {
        let arr = child.children.filter((x) => x);
        arr.forEach((a) => {
          this.dispose(child, a);
        });
      }
      if (child instanceof THREE.Mesh || child instanceof THREE.LineSegments) {
        if (child.material.map)
          if (child.material.map.dispose) child.material.map.dispose();
        if (child.geometry.dispose) child.geometry.dispose();
        if (child.material.dispose) child.material.dispose();
      } else if (child.material) {
        child.material.dispose();
      }
      child.remove();
      parent.remove(child);
    },

    flowLightEffect() {
      let that = this;

      let repeatTime = 2;
      let group = new THREE.Group();
      let path1 = [
        new THREE.Vector3(-44.378, 0.018, -62.886),
        new THREE.Vector3(-46.101, 0.018, 62.719),
      ];
      let path2 = [
        new THREE.Vector3(-49.803, 0.018, 62.601),
        new THREE.Vector3(-47.416, 0.018, -62.976),
      ];

      let path3 = [
        new THREE.Vector3(74.469, 0.079, -17.685),
        new THREE.Vector3(-74.391, 0.018, -43.875),
      ];

      let path4 = [
        new THREE.Vector3(-74.457, 0.079, -41.954),
        new THREE.Vector3(74.373, 0.079, -15.671),
      ];

      let path5 = [
        new THREE.Vector3(-19.964, 0.018, 26.683),
        new THREE.Vector3(35.876, 0.018, 25.777),
        new THREE.Vector3(35.499, 0.018, -18.27),
        new THREE.Vector3(21.662, 0.018, -21.082),
        new THREE.Vector3(-20.297, 0.018, -21.469),
      ];

      let path6 = [
        new THREE.Vector3(35.499, 0.018, -18.27),
        new THREE.Vector3(21.662, 0.018, -21.082),
        new THREE.Vector3(-20.297, 0.018, -21.469),
        new THREE.Vector3(-19.964, 0.018, 26.683),
        new THREE.Vector3(35.876, 0.018, 25.777),
      ];

      let paths = [path1, path2, path3, path4, path5, path6];

      for (let i in paths) {
        let path = paths[i];
        let curve = new THREE.CatmullRomCurve3(path, true);
        let index = i % 2;
        let flow = addline(curve, index);
        group.add(flow);

        Tween.gsap.to(flow.material.map.offset, {
          x: -1,
          duration: repeatTime,
          ease: "none",
          repeat: -1,
          onUpdate: () => {
            flow.material.map.needsUpdate = true;
          },
        });
      }
      this.scene.add(group);

      return group;

      // // let path1 = [].concat(...paths)
      // let curve1 = new THREE.CatmullRomCurve3(path1, true/*是否闭合*/);

      // let curve2 = new THREE.CatmullRomCurve3(path2, true);

      // let flow1 = addline(curve1, 1);
      // let flow2 = addline(curve2, 0);

      // flow1.sceneStateFlag = [SCENESTATE.NORMAL];
      // flow2.sceneStateFlag = [SCENESTATE.NORMAL];

      // group.add(flow1, flow2)
      // this.scene.add(group);

      // Tween.gsap.to(flow1.material.map.offset, {
      // 	x: -1, duration: repeatTime, ease: 'none', repeat: -1,
      // 	onUpdate: () => {
      // 		flow1.material.map.needsUpdate = true;
      // 	}
      // })

      // Tween.gsap.to(flow2.material.map.offset, {
      // 	x: -1, duration: repeatTime, ease: 'none', repeat: -1,
      // 	onUpdate: () => {
      // 		flow2.material.map.needsUpdate = true;
      // 	}
      // })

      function addline(curve, state, g) {
        curve.curveType = "catmullrom";
        curve.tension = 0.01;
        let color;

        if (state == 0) {
          color = "static/textures/light0.png";
        } else {
          color = "static/textures/light1.png";
        }

        let k = 0.01; //截面
        let tubeGeometry = new THREE.TubeGeometry(curve, 1000, 0.1, 8, false);
        let textureLoader = new THREE.TextureLoader();
        let texture = textureLoader.load(color);
        texture.repeat.set(1, 1);
        texture.wrapS = THREE.RepeatWrapping;
        texture.wrapT = THREE.RepeatWrapping;

        let tubeMaterial = new THREE.MeshPhongMaterial({
          map: texture,
          transparent: true,
          opacity: 0.5,
          depthWrite: false,
        });

        let mesh = new THREE.Mesh(tubeGeometry, tubeMaterial);
        mesh.name = "flowlight";
        mesh.layers = that.layers_1;
        return mesh;
      }
    },

    modelBloomEffect(groups = this.bloomModels) {
      for (let g of groups) {
        this.bloom_group(g);
      }
    },

    change_shadow: function (x, y, z, l) {
      this.scene.remove(this.dirLight.target);
      this.scene.remove(this.dirLight);
      this.dirLight = new THREE.DirectionalLight(0xffffff, 0.8);
      this.dirLight.castShadow = true;
      this.dirLight.position.set(x, y, z);
      this.scene.add(this.dirLight.target);
      this.dirLight.target.position.copy(
        new THREE.Vector3(x + 1, y - 3, z - 1)
      ); //(-1,3,1)
      this.dirLight.shadow.mapSize.width = 1024 * 4; // default
      this.dirLight.shadow.mapSize.height = 1024 * 4; // default
      this.dirLight.shadow.camera.near = 0 - l; //产生阴影的最近距离
      this.dirLight.shadow.camera.far = l; //产生阴影的最远距离
      this.dirLight.shadow.camera.left = 0 - l; //产生阴影距离位置的最左边位置
      this.dirLight.shadow.camera.right = l; //最右边
      this.dirLight.shadow.camera.top = l; //最上边
      this.dirLight.shadow.camera.bottom = 0 - l; //最下面
      this.dirLight.shadow.radius = 2;
      this.scene.add(this.dirLight);
    },

    change_shadow0: function () {
      this.scene.remove(this.dirLight.target);
      this.scene.remove(this.dirLight);

      this.dirLight = new THREE.DirectionalLight(0xffffff, 1);
      this.dirLight.castShadow = true;
      this.dirLight.position.set(1, 2, -1);
      this.dirLight.target.position.copy(this.scene.position);
      this.dirLight.shadow.mapSize.width = 1024 * 2;
      this.dirLight.shadow.mapSize.height = 1024 * 2;
      let l = 100;
      this.dirLight.shadow.camera.near = 0 - l; //产生阴影的最近距离
      this.dirLight.shadow.camera.far = l; //产生阴影的最远距离
      this.dirLight.shadow.camera.left = 0 - l; //产生阴影距离位置的最左边位置
      this.dirLight.shadow.camera.right = l; //最右边
      this.dirLight.shadow.camera.top = l; //最上边
      this.dirLight.shadow.camera.bottom = 0 - l; //最下面
      this.dirLight.shadow.radius = 3;
      this.scene.add(this.dirLight);
    },

    outPosition() {
      var { x, y, z } = this.controls.target;
      x = parseFloat(x.toFixed(3));
      y = parseFloat(y.toFixed(3));
      z = parseFloat(z.toFixed(3));
      let a = [x, y, z];
      var { x, y, z } = this.camera.position;
      x = parseFloat(x.toFixed(3));
      y = parseFloat(y.toFixed(3));
      z = parseFloat(z.toFixed(3));
      let v3 = `new THREE.Vector3(${a[0]},${a[1]},${a[2]}), new THREE.Vector3(${x},${y},${z}), `;
      console.log([...a, x, y, z], ` ${v3}`);
    },
    scrollspeed() {
      // 获取滚动容器
      let scrollContainer = document.getElementById("box");

      // 滚动动画的当前目标位置
      let scrollTarget = 0;

      // 滚动动画的当前位置（与scrollContainer.scrollTop同步）
      let scrollCurrent = 0;

      // 平滑滚动的速度（像素/帧）
      let scrollSpeed = 5;

      // 监听容器的wheel事件
      scrollContainer.addEventListener("wheel", function (event) {
        // 阻止默认行为（如果你不希望页面也滚动的话）
        event.preventDefault();

        // 计算滚动的目标位置
        // 这里我们假设每次滚轮滚动都向上滚动一定距离
        let deltaY = event.deltaY;
        if (deltaY > 0) {
          // 向上滚动
          scrollTarget = Math.max(0, scrollCurrent + 100);
        } else {
          scrollTarget = Math.max(0, scrollCurrent - 100);
        }

        // 开始或继续平滑滚动动画
        smoothScroll();
      });

      // 平滑滚动动画的函数
      function smoothScroll() {
        if (scrollCurrent !== scrollTarget) {
          // 计算当前帧应该滚动到的位置
          let step = Math.sign(scrollTarget - scrollCurrent) * scrollSpeed;
          scrollCurrent += step;
          // 更新滚动容器的scrollTop
          scrollContainer.scrollTop = scrollCurrent;

          // 递归调用requestAnimationFrame以继续动画
          requestAnimationFrame(smoothScroll);
        }
      }
    },
  },

  beforeMount() {},

  mounted() {
    this.sprd = [];
    this.billboards = [];
    this.bloomModels = [];
    this.container;
    this.scene;
    this.controls;
    this.camera;
    this.renderer;
    this.raycaster;
    this.clock = new THREE.Clock();
    this.mouse = new THREE.Vector2();
    this.selectedObjects = [];
    this.composer;
    this.effectFXAA;
    this.outlinePass;
    this.outlinePass1;
    this.outlinePass2;
    this.outlinePass3;
    this.ldrCubeMap0;
    this.ldrCubeMap1;
    this.ldrCubeMap;
    this.theldrCubeMap;
    this.allPer = 0;
    this.tem_e = { x: 0, y: 0 };
    this.tem_right_e = { x: 0, y: 0 };
    this.dirLight;
    this.gif = [];
    this.effectmats = []; //飞线
    this.group_0 = new THREE.Group();
    this.group_1 = new THREE.Group();
    this.group_2 = new THREE.Group();
    this.group_h = new THREE.Group();
    this.group_h2 = new THREE.Group();
    this.group_pm = new THREE.Group();
    this.group_water = new THREE.Group();
    this.group_light = new THREE.Group();
    this.group_circle = new THREE.Group();
    this.currentLayer = new THREE.Layers();
    this.layers_0 = new THREE.Layers();
    this.layers_1 = new THREE.Layers();
    this.layers_hide = new THREE.Layers();

    this.lines = [];
    this.state = [];
    this.i_data = {};
    this.model_o = {};
    this.model_l = {};
    this.active_sel = [];
    this.model_yp = {};
    this.model_floor = [];

    //单个模型对象
    this.model_ob = {};
    this.choose_sp = { x: 0, y: 0, z: 0 }; //选中的sprite
    this.hover_sp = { x: 0, y: 0, z: 0 }; //选中的sprite
    this.mt_water = [];
    this.material_circle = [];
    this.point_texture = {};

    this.materials = {};
    this.FPS = 60;
    this.renderT = 1 / this.FPS;
    this.timeS = 0;
    this.lines_ele = [];
    this.main_mt = [];

    //辉光
    // this.params = {
    //   exposure: 1,
    //   bloomThreshold: 1,
    //   bloomStrength: 3.5,
    //   bloomRadius: 1,
    // };

    this.params = {
      exposure: 0.1,
      bloomThreshold: 0,
      bloomStrength: 0.29,
      bloomRadius: 0,
    };
    this.bloomLayer = new THREE.Layers();
    this.darkMaterial = new THREE.MeshBasicMaterial({
      color: new THREE.Color(0x000000),
      transparent: true,
    });
    this.darkMaterial_t = new THREE.SpriteMaterial({
      color: new THREE.Color(0x000000),
      transparent: true,
    });

    this.bloomPass;
    this.finalComposer;
    this.requestId;
    this.onload();
  },
};
</script>
<style scoped lang="less">
#container {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 0;
  pointer-events: auto;
}
@media screen and(max-width: 900px) {
  #container {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 0;
    pointer-events: none;
  }
}
.home {
  position: absolute;
  top: 10px;
  right: 10px;
}

.rotate {
  position: absolute;
  top: 50px;
  right: 10px;
}

.hover_name {
  position: absolute;
  *left: calc(50% - 80px);
  *margin: 10px auto;
  *top: 90px;
  color: white;
  font-size: 2vh;
  pointer-events: none;
  border: 2px solid #3695ff;
  -moz-border-radius: 15px;
  -webkit-border-radius: 15px;
  border-radius: 5px;
  padding: 1vh;
  *background: linear-gradient(rgba(6, 42, 108, 0.8), rgba(3, 19, 44, 0.8));
  background: linear-gradient(rgba(22, 31, 60, 0.6), rgba(13, 19, 36, 0.8));
}

.info {
  z-index: 2;
}

.info2 {
  z-index: 2;
}
.btn {
  width: 3vw;
  height: 4vh;
  line-height: 4vh;
  background-color: #353333;
  color: white;
  left: 50%;
  bottom: 15vh;
  transform: translateX(-50%);
  position: absolute;
  text-align: center;
}
</style>
